/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // $('.unslider').unslider({
        //   autoplay: true,
        //   infinite: true,
        //   arrows: true,
        //   nav: false
        // });

        // http://stackoverflow.com/questions/14804941/how-to-add-smooth-scrolling-to-bootstraps-scroll-spy-function
        $("#myNav ul li a[href^='#']").on('click', function(e) {

          // prevent default anchor click behavior
          e.preventDefault();

          // store hash
          var hash = this.hash;

          // Cerrar menú en móvil al clicar cualquier opción
          $('.navbar-collapse').collapse('toggle');

          // animate
          $('html, body').animate({
            scrollTop: $(hash).offset().top -80
          }, 500, function(){
            // when done, add hash to url
            // (default click behaviour)
            // window.location.hash = hash;
          });

        });
        // Cerrar menú en móvil al clicar cualquier opción
        // $('.navbar-collapse a').click(function (e) {
        //   $('.navbar-collapse').collapse('toggle');
        // });

/*        $( ".hover-expand" ).hover(
            function() {
                if (! $(this).hasClass('collapsing') && 
                    $(this).hasClass('collapsed')) {
                        $( this ).click();
                }
            }, function() {
                if  (! $(this).hasClass('collapsing') || 
                     ! $(this).hasClass('collapsed')) {
                        $( this ).click();
                }
            }
        );*/


/*        $('.collapse').on('shown.bs.collapse hidden.bs.collapse', function() {
          $(this).dequeue('collapse');
        });*/
/*        $('.hover-expand').hover(
          function() {
            var collapse = $($(this).find('a').attr('href'));
            collapse.queue('collapse', function() {
              $(this).collapse('show');
            });
            if (!collapse.hasClass('collapsing')) {
              collapse.dequeue('collapse');
            }
          },
          function() {
            var collapse = $($(this).find('a').attr('href'));
            collapse.queue('collapse', function() {
              $(this).collapse('hide');
            });
            if (!collapse.hasClass('collapsing')) {
              collapse.dequeue('collapse');
            }
          }
        );*/
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
